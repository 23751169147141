<template>
<transition name="modal-fade">

    <section class="c-section c-section--modal">

        <div class="c-modal">

            <div class="c-modal__inner">
                <div class="c-modal__actions">
                    <button class="c-modal__close" type="button" @click="close"></button>
                </div>
                <div class="c-modal__information">
                    <div class="c-modal__instruction">
                        <div v-if="state !== 'submitted'" class="c-modal__init">
                            <h2 class="c-modal__title">Add your rose to the garden</h2>
                            <p>It’s time to add your creation to the garden. Upload an image of your rose along with a message (this can be the symptoms or a personal story) using our builder and then get sharing!</p>
                            <p>
                                If you haven’t already, download your craft pack, follow <a href="downloads/Ovarian-Cancer-Action-Pack.zip" download>this link</a> to get started.
                            </p>
                            <p>
                                By submitting a rose you agree to our <a rel="noopener" target="_blank" href="https://ovarian.org.uk/get-involved/donate/text-donate-terms-conditions/">terms and conditions</a>
                            </p>
                            <portal to="initButtons" :disabled="mobile" slim>
                                <div class="c-buttons portal">
                                    <button :disabled="state === 'pending' || ($v.$anyDirty && $v.$anyError)" @click="submit">
                                        Upload your rose
                                    </button>
                                </div>
                            </portal>

                            <div v-if="!$v.entry.author.required && $v.entry.author.$dirty" class="c-form__error">
                                The name field is required.
                            </div>
                            <div v-if="!$v.entry.message.required && $v.entry.message.$dirty" class="c-form__error">
                                The message field is required.
                            </div>
                            <div v-if="!$v.entry.image.required && $v.entry.image.$dirty" class="c-form__error">
                                Please select an image to upload.
                            </div>
                            <div v-if="!$v.file.size.maxValue && $v.file.size.$dirty" class="c-form__error">
                                Please upload a photo that’s no more than 7MB in size.
                            </div>
                            <div v-if="!$v.file.type.imageMimetype && $v.file.type.$dirty" class="c-form__error">
                                Please upload an image file (e.g. JPG, PNG).
                            </div>
                            <div v-if="submitError" class="c-form__error" v-text="submitError">
                            </div>
                        </div>

                        <div class="c-modal__submitted" v-if="this.state == 'submitted'">
                            <h2 class="c-modal__title">Join, share, donate</h2>
                            <p>
                                Congratulations, you are now part of the <router-link :to="{name: 'Garden' }">UK's largest rose garden!</router-link>
                            </p>
                            <p>
                                Ovarian Cancer Action is on a mission to take action against ovarian cancer by raising awareness and funding life-saving research. Share your rose with the symptoms on social using <a rel="noreferrer noopener"
                                  target="_blank" href="https://twitter.com/search?q=ocarosegarden"></a>#OCARoseGarden and donate to
                                transform the lives and prospects of women today and for generations to come.
                            </p>

                            <div class="c-buttons">

                                <div class="c-buttons__social">
                                    <a class="c-button__social" rel="noreferrer noopener" target="_blank" :href="entry.facebook_url" @click.prevent="sharePopup($event.currentTarget)">
                                        <svg-vue icon="social-facebook"></svg-vue>
                                    </a>
                                    <a class="c-button__social" rel="noreferrer noopener" target="_blank" :href="entry.twitter_url" @click.prevent="sharePopup($event.currentTarget)">
                                        <svg-vue icon="social-twitter"></svg-vue>
                                    </a>
                                    <a class="c-button__social" rel="noreferrer noopener" target="_blank" href="https://www.instagram.com/ovariancanceraction/">
                                        <svg-vue icon="social-instagram"></svg-vue>
                                    </a>
                                </div>

                                <hr />

                                <a href="https://donate.ovarian.org.uk/" class="c-button">Donate Now</a>

                            </div>

                        </div>
                    </div>
                    <div class="c-modal__form">
                        <Card :entry.sync="entry" :title="card.title" :description="card.description" :image="card.image_url" :editable="state !== 'submitted'" :image-uploading="imageUploading" @image-selected="uploadImage"></Card>
                        <portal-target name="initButtons" slim></portal-target>
                    </div>
                </div>
            </div>

        </div>

    </section>

</transition>
</template>

<script>
import Card from '../components/Card'
import Compressor from 'compressorjs'
import {
    required,
    helpers,
    maxValue
} from 'vuelidate/lib/validators'
import _forEach from 'lodash.foreach'

const imageMimetype = helpers.regex('imageMimetype', /^image\/[a-z]*$/)


export default {
    name: "Modal",
    components: {
        'Card': Card,
    },
    props: {
        maxAssetSize: {
            type: Number,
            default: 0
        }
    },
    mounted() {
        this.isMobile();
        window.addEventListener('resize', () => {
            this.isMobile();
        })
    },
    data() {
        return {
            state: "init",
            mobile: false,
            imageUploading: false,
            submitError: false,

            // display props for saved entry data
            card: {
                title: '',
                image_url: '',
                description: '',
            },

            // synced props for form data
            file: {
                size: 0,
                type: ''
            },
            entry: {
                author: '',
                message: '',
                image: '',
            }
        }
    },
    validations() {
        return {
            file: {
                size: {
                    maxValue: maxValue(this.maxAssetSize)
                },
                type: {
                    imageMimetype: imageMimetype
                }
            },
            entry: {
                author: {
                    required: required
                },
                message: {
                    required: required
                },
                image: {
                    required: required
                }
            }
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        uploadImage(image) {
            this.file.size = image.size
            this.file.type = image.type
            this.$v.file.$touch()

            if (!image || this.$v.file.$invalid) {
                return
            }

            this.imageUploading = true

            let _this = this

            new Compressor(image, {
                minWidth: 800,
                minHeight: 800,
                maxHeight: 2000,
                maxWidth: 2000,
                success(file) {
                    let data = new FormData()
                    data.append('image', file)

                    axios.post('/api/entry/image', data)
                        .then(response => {
                            _this.entry.image = response.data.file
                            _this.card.image_url = response.data.url
                        })
                        .catch(error => {
                            console.log(error);
                        })
                        .then(() => {
                            _this.imageUploading = false
                        })
                }
            })
        },
        submit() {
            this.$v.$touch()

            if (this.$v.entry.$invalid) {
                this.state = 'error'
            } else {
                this.state = 'pending'

                axios.post('/api/entry', this.entry)
                    .then(response => {
                        this.state = 'submitted'
                        this.entry = response.data;
                        this.card.title = this.entry.author
                        this.card.description = this.entry.message
                    })
                    .catch(error => {
                        this.state = 'init'

                        if (error.response.status === 422) {
                            let err = 'Your entry couldn’t be saved: '

                            _forEach(error.response.data.errors, (field) => {
                                err = err + field.join(' ') + ' '
                            })

                            this.submitError = err
                        } else {
                            this.submitError = 'An error occurred while saving your entry.'
                        }
                    })
            }
        },
        isMobile() {
            this.mobile = (window.innerWidth < 768) ? false : true
        },
        sharePopup(target) {
            let width = 570,
                height = 300,
                left = (screen.width / 2) - (width / 2),
                top = (screen.height / 2) - (height / 2)

            window.open(
                target.getAttribute('href'),
                'share-window',
                'width=' + width + ', height=' + height + ', top=' + top + ', left=' + left
            );
        }
    },
}
</script>
