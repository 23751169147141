<template>
<section class="c-section c-section--detail  c-section--2" id="shareForm">
    <div class="c-form c-branch">
        <div class="c-form__inner">
            <div class="c-form__container">
                <h3>Download your rose craft pack</h3>
                <p>
                    90% of women don’t know the four main symptoms of ovarian cancer. But you can help change that. Create and share a white rose to help raise awareness.
                </p>

                <p>
                    You can create a rose any way you like but if you need a little help we've pulled together some rose designs and instructions.
                </p>
                <p class="c-form__bold">
                    Enter your details below and we'll send through a rose craft pack to get you started.
                </p>
                <div>
                    <label for="name">Full name</label>
                    <input id="name" v-model.trim="lead.name" :class="{ 'error': $v.lead.name.$error }" type="text" name="name" placeholder="Full name" maxlength="255" />
                    <div v-if="!$v.lead.name.required && $v.lead.name.$dirty" class="c-form__error">
                        The name field is required.
                    </div>

                    <label for="email">Email address</label>
                    <input id="email" v-model.trim="lead.email" :class="{ 'error': $v.lead.email.$error }" type="email" name="email" placeholder="Email address" maxlength="255" />
                    <div v-if="!$v.lead.email.required && $v.lead.email.$dirty" class="c-form__error">
                        The email field is required.
                    </div>
                    <div v-if="!$v.lead.email.email && $v.lead.email.$dirty" class="c-form__error">
                        Please enter a valid email address.
                    </div>

                    <label class="c-checkbox c-checkbox__small">Select to subscribe to our enewsletter to keep up to date with all the latest events and news with Ovarian Cancer Action. See our <a rel="noreferrer noopener" target="_blank"
                          href="https://ovarian.org.uk/privacy-policy/">privacy policy</a> for
                        how we look after your data
                        <input v-model="lead.newsletter" type="checkbox" name="newsletter">
                        <span class="checkmark"></span>
                    </label>
                    <hr />
                    <label class="c-checkbox">Select if you would like to send this pack to your friend.
                        <input v-model="shared" type="checkbox" name="shared">
                        <span class="checkmark"></span>
                    </label>
                    <transition name="fade">
                        <div v-if="shared">
                            <input id="friend" v-model="lead.friend_email" :class="{ 'error': $v.lead.friend_email.$error }" type="email" name="friend_email" placeholder="Your friend's email address" maxlength="255" />
                            <div v-if="!$v.lead.friend_email.email && $v.lead.friend_email.$dirty" class="c-form__error">
                                Please enter a valid email address.
                            </div>
                        </div>
                    </transition>
                    <p>
                        Don't worry, we will not store or share your friend's personal data.
                    </p>
                    <div class="c-buttons">
                        <button class="c-button" :disabled="submitStatus === 'PENDING'" @click="submit">
                            Send pack
                        </button>
                    </div>

                    <p v-if="submissionError" class="c-form__error">
                        {{ submissionError }}
                    </p>
                    <p v-if="submitStatus=== 'SUCCESS'" class="c-form__notice">
                        Thanks for your interest, your craft pack should arrive in the next few minutes.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    required,
    email
} from 'vuelidate/lib/validators'
import _forEach from 'lodash.foreach'

export default {
    data: function() {
        return {
            shared: false,
            submissionError: null,
            submitStatus: null,
            lead: {
                name: '',
                email: '',
                friend_email: '',
                newsletter: false
            },
        }
    },
    watch: {
        shared: function(isShared) {
            // clear the friend email value if the sharing option is unchecked
            if (isShared === false) {
                this.lead.friend_email = ''
            }
        }
    },
    validations: {
        lead: {
            name: {
                required
            },
            email: {
                required,
                email
            },
            friend_email: {
                email
            }
        }
    },
    methods: {
        submit() {
            this.$v.$touch()

            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                this.submitStatus = 'PENDING'

                axios.post('/api/lead', this.lead)
                    .then(response => {
                        this.submitStatus = 'SUCCESS'
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            let err = ''

                            _forEach(error.response.data.errors, (field) => {
                                err = err + field.join(' ') + ' '
                            })

                            this.submissionError = err
                        } else {
                            this.submissionError = 'An error occurred while saving your moment.'
                        }
                    })
            }
        }
    }
}
</script>
