<template>
<transition name="fade">
    <div class="c-card">
        <div class="c-card__link">
            <div class="c-media">
                <div
                    v-if="editable === false || image !== ''"
                    class="c-card__media lazyload"
                    :data-background="image"
                >
                    <svg-vue icon="card-grass"></svg-vue>
                </div>
                <div
                    v-else
                    class="c-card__upload"
                >
                    <input
                        ref="image"
                        type="file"
                        name="image"
                        accept="image/*"
                        @change="emitImage"
                    >

                    <a class="c-card__media">
                        Add image
                        <svg-vue icon="card-grass"></svg-vue>
                        <div
                            v-if="imageUploading"
                            class="c-card__loading"
                        >
                            <div class="loading-indicator">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div
                v-if="editable === false"
                class="c-card__content"
            >
                <h3 class="c-card__title">{{ title }}</h3>
                <p class="c-card__description">{{ description }}</p>
            </div>
            <div
                v-else
                class="c-card__content"
            >
                <form>
                    <input
                        v-model="entry.author"
                        type="text"
                        class="c-card__title"
                        placeholder="Tap to enter name"
                        maxlength="40"
                        :class="{'empty': isEmptyTitle(entry.author)}"
                    >
                    <textarea
                        v-model="entry.message"
                        class="c-card__description"
                        maxlength="150"
                        placeholder="Tap to enter your message maximum 150 characters…"
                        :class="{'empty': isEmptyDescription(entry.message)}"
                    ></textarea>
                </form>
            </div>

        </div>
    </div>
</transition>
</template>

<script>
export default {
    props: {
        title: null,
        description: null,
        image: null,
        editable: {
            type: Boolean,
            default: false
        },
        entry: {
            type: Object,
            default: null
        },
        imageUploading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    methods: {

        isEmptyTitle() {
            return (this.entry.author === null || !this.entry.author.length)
        },
        isEmptyDescription() {
            return (this.entry.message === null || !this.entry.message.length)
        },
        emitImage() {
            this.$emit('image-selected', this.$refs.image.files[0])
        }

    },
    computed: {
    },
    directives: {

        focus: {

            inserted(el) {
                el.focus()
            }

        }

    }
}
</script>
