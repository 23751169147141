<template>
<footer class="c-footer" v-bind:class="{  'c-branch': getType }">

    <hr v-if="getType" />

    <div class="c-footer__inner">

        <div class="c-footer__large" v-if="getType">

            <div class="c-footer__top">
                <h2>Other ways to help Ovarian Cancer Action</h2>
                <img v-bind:src="imgFooter"></img>
            </div>

            <div class="c-footer__bottom">
                <div class="c-footer__left">
                    <h3>Donate now</h3>
                    <p>
                        Your donations help us to fund the next generation of life-saving research. Donate to transform the lives and prospects of women today and for generations to come.
                    </p>
                    <div class="c-buttons">
                        <a href="https://donate.ovarian.org.uk/" class="c-button">Donate now</a>
                    </div>
                </div>
                <div class="c-footer__right">
                    <h3>Take part</h3>
                    <p>
                        Every year, 295,000 women around the world are diagnosed with ovarian cancer and only half will survive beyond five years. But you can help change this. Join our Walk In Her Name Step Challenge and take 295,000 steps in May to
                        fund life-saving research.
                    </p>
                    <p>
                        Take part as an individual or a team, track your progress on our brand new online platform and connect with others in the Step Challenge community. We’ll be there with you throughout the month to help you stay active,
                        motivated and achieve your goal.
                    </p>
                    <div class="c-buttons">
                        <a class="c-button" rel="noopener" target="_blank" href="https://www.stepchallenge.org.uk/">Join the Step Challenge</a>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <hr />

    <p class="c-footer__signature">
        Copyright Ovarian Cancer Action | Registered charity no. 1109743 (England & Wales) and SC043478 (Scotland) | <a rel="noopener" target="_blank" href="https://ovarian.org.uk/privacy-policy/">Privacy Policy</a> | <a rel="noopener"
          target="_blank" href="https://ovarian.org.uk/get-involved/donate/text-donate-terms-conditions/">Terms and Conditions</a>
    </p>

    <cookie-law>
        <div slot-scope="props">
            <h2 class="c-cookie__title">
                Cookie information
            </h2>
            <p class="c-cookie__description">
                We use cookies to ensure that we give you the best experience on our website. <a rel="noreferrer" target="_blank" href="https://ovarian.org.uk/privacy-policy/">Click here</a> for more information.
            </p>
            <button class="skew" @click="props.accept"><span>I accept</span></button>
        </div>
    </cookie-law>

</footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law';

export default {
    name: 'Footer',
    components: {
        CookieLaw: CookieLaw
    },
    props: {
        large: null,
    },
    computed: {

        getType() {

            return this.large || false

        },

    },
    mounted() {
        this.responsiveFooterIMG();
        window.addEventListener('resize', () => {
            this.responsiveFooterIMG();
        });
    },
    methods: {
        responsiveFooterIMG: function() {
            if (window.innerWidth > 1024) {
                this.imgFooter = "images/branch/branch-desktop-footer.png"
            } else if (window.innerWidth > 768) {
                this.imgFooter = "images/branch/branch-mobile-footer.png"
            } else if (window.innerWidth < 768) {
                this.imgFooter = "images/branch/branch-mobile-footer.png"
            }
        },
    },
    data() {
        return {
            imgFooter: "images/branch/branch-mobile-footer.png",
        }
    },
}
</script>
