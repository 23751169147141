window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Vue JS
import App from './views/App'
import Vue from "vue"
import VueRouter from 'vue-router'
import SvgVue from 'svg-vue'
import Vuelidate from 'vuelidate'
import PortalVue from 'portal-vue'
import Home from "./views/Home"
import Garden from "./views/Garden"
import Create from "./views/Create"

// Other Components
import 'lazysizes';

Vue.use(VueRouter)
Vue.use(SvgVue)
Vue.use(Vuelidate)
Vue.use(PortalVue)

const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "current-page-item",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/garden",
      name: "Garden",
      component: Garden,
    },
    {
        path: "/garden/:id(\\d+)",
        name: "Entry",
        component: Garden,
    },
    {
      path: "/create",
      name: "Create",
      component: Create,
    },
  ]
});

const app = new Vue({
  el: "#app",
  components: { App },
  router,
});
