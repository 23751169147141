<template>
<div class="c-main">
    <section class="c-section c-section--garden">
        <div class="c-garden">
            <div class="c-garden__inner">
                <h2 v-if="sharePage === false" class="c-garden__title">
                    Welcome to the UK's largest rose garden
                </h2>
                <div v-if="sharePage === true" class="c-garden__card">
                    <Card :key="sharedCard.id" :title="sharedCard.author" :description="sharedCard.message" :image="sharedCard.image_url"></Card>
                </div>
                <div v-if="sharePage === false" class="c-garden__share">
                    <h3>Share your rose</h3>
                    <p>
                        Scroll through our rose garden to look at the beautiful rose creations made by people all over the UK. Come and join by creating your own and uploading it.
                    </p>
                    <p>
                        Every rose and every donation helps to spread awareness of ovarian cancer and fund life-saving research. Help us to transform the lives and prospects of women today and for generations to come.
                    </p>
                    <div class="c-buttons">
                        <a class="c-button" @click="showModal">Add your rose</a>
                        <a class="c-button" rel="noopener" target="_blank" href="https://donate.ovarian.org.uk/">Donate now</a>
                    </div>
                </div>
                <div v-if="sharePage" class="c-garden__share">
                    <h3>The UK’s largest rose garden</h3>
                    <p>
                        Scroll through our rose garden to look at the beautiful rose creations made by people all over the UK. Come and join by creating your own and uploading it.
                    </p>
                    <p>
                        Every rose and every donation helps to spread awareness of ovarian cancer and fund life-saving research. Help us to transform the lives and prospects of women today and for generations to come.
                    </p>
                    <div class="c-buttons">
                        <router-link class="c-button" :to="{name: 'Home' }">
                            Find out more
                        </router-link>
                        <a class="c-button" @click="showModal">
                            Add your rose
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="c-section c-section--cards">
        <div class="c-cards__grass">
            <svg-vue :key="componentKey" :icon="svgBanner"></svg-vue>
        </div>
        <div class="c-cards">
            <div class="c-cards__inner c-cards--4up" :class="remainderClass">
                <Card v-for="entry in entries" :key="entry.id" :title="entry.author" :description="entry.message" :image="entry.image_url"></Card>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import _throttle from 'lodash.throttle'
import Card from '../components/Card'

export default {
    name: 'Garden',
    components: {
        "Card": Card
    },
    mounted() {
        this.get_card_remainder();
        this.responsiveSVG();
        window.addEventListener('resize', () => {
            this.responsiveSVG();
        });

        window.addEventListener('scroll', _throttle(() => {
            this.handleScroll()
        }, 250))
    },
    created() {
        this.getNextPage(true)

        // Monitor for entry-specific share URL requests
        if (typeof this.$route.params.id !== 'undefined') {
            this.getEntryCard(this.$route.params.id)
        }
    },
    methods: {
        showModal() {
            this.$emit('show-modal');
        },
        get_card_remainder() {
            const count = this.entries.length;
            this.remainder = this.entries.length % this.columns;
            this.remainderClass = `c-cards--${this.remainder}down`;
        },
        responsiveSVG() {

            if (window.innerWidth > 1024) {
                this.svgBanner = "desktop-grass"
            } else if (window.innerWidth > 768) {
                this.svgBanner = "tablet-grass"
            } else if (window.innerWidth < 768) {
                this.svgBanner = "mobile-grass"
            }
            this.componentKey += 1;

        },
        handleScroll() {
            if (this.nextPage === false) {
                return
            }

            let supportPageOffset = window.pageXOffset !== undefined
            let isCSS1Compat = ((document.compatMode || "") === "CSS1Compat")

            let yOffset = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
            let visibleOffset = yOffset + window.innerHeight

            // load next page if we scroll within 300px of the footer
            if ((document.querySelector('.c-footer').offsetTop - visibleOffset) < 300) {
                this.getNextPage()
            }
        },
        getNextPage(firstPage) {
            if (firstPage === true) {
                this.nextPage = '/api/entries'
            }

            // we’ve scrolled to the end or we’re already requesting an update
            if (this.nextPage === null || this.activeRequest) {
                return
            }

            this.activeRequest = true

            axios.get(this.nextPage)
                .then(response => {
                    this.entries.push(...response.data.data)
                    this.nextPage = response.data.next_page_url
                })
                .catch(error => {})
                .then(() => {
                    this.activeRequest = false
                })
        },
        getEntryCard(id) {
            axios.get('/api/entry/' + id)
                .then(response => {
                    this.sharePage = true
                    this.sharedCard = response.data
                })
        }
    },
    data() {
        return {
            columns: 4,
            remainder: 0,
            remainderClass: null,
            componentKey: 0,
            svgBanner: "mobile-grass",
            nextPage: null,
            activeRequest: false,
            entries: [],
            sharePage: false,
            sharedCard: null
        }
    },
}
</script>
