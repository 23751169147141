<template>
<header id="c-header" class="c-header">

    <div id="c-header__inner" class="c-header__inner">

        <div class="c-header__controls">
            <router-link class="c-header__logo" :to="{name: 'Home' }">
                <svg-vue icon="logo-oca"></svg-vue>
            </router-link>
        </div>

        <div id="nav-toggle" class="c-header__hamburger">
            <div class="c-hamburger" v-on:click="toggleMenu">
                <a class="c-hamburger__patty"></a>
            </div>
        </div>

        <div class="c-header__panel" id="header-panel">

            <div class="c-header__menu">

                <div class="c-header__controls">
                    <router-link class="c-header__logo" :to="{name: 'Home' }">
                        <svg-vue icon="logo-oca"></svg-vue>
                    </router-link>
                </div>

                <nav class="c-nav-primary">
                    <ul id="navigation-primary" class="c-nav-primary__list">
                        <li>
                            <router-link :to="{name: 'Home' }"><span class="c-nav-primary__title">Home</span></router-link>
                        </li>
                        <li><a @click="showModal"><span class="c-nav-primary__title">Add your rose</span></a></li>
                        <li>
                            <router-link :to="{name: 'Garden' }"><span class="c-nav-primary__title">View the garden</span></router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'Create' }"><span class="c-nav-primary__title">Tutorials</span></router-link>
                        </li>
                        <li>
                            <a rel="noopener" target="_blank" href="https://ovarian.org.uk/ovarian-cancer/ovarian-cancer-symptoms/"><span class="c-nav-primary__title">About the Symptoms</span>
                                <span class="c-nav-primary__icon">
                                    <svg-vue icon="icon-external"></svg-vue>
                                </span>
                            </a>
                        </li>
                        <li>
                            <a rel="noopener" target="_blank" href="https://donate.ovarian.org.uk/"><span class="c-nav-primary__title">Donate</span>
                                <span class="c-nav-primary__icon">
                                    <svg-vue icon="icon-external"></svg-vue>
                                </span>
                            </a>
                        </li>
                    </ul>

                    <nav class="c-nav-social">

                        <hr />

                        <ul class="c-nav-social__list">
                            <li>
                                <a rel="noopener" target="_blank" href="https://www.facebook.com/ovariancanceraction">
                                    <svg-vue icon="social-facebook"></svg-vue>
                                </a>
                            </li>
                            <li>
                                <a rel="noopener" target="_blank" href="https://twitter.com/OvarianCancerUK">
                                    <svg-vue icon="social-twitter"></svg-vue>
                                </a>
                            </li>
                            <li>
                                <a rel="noopener" target="_blank" href="https://www.instagram.com/ovariancanceraction/">
                                    <svg-vue icon="social-instagram"></svg-vue>
                                </a>
                            </li>
                        </ul>

                    </nav>

                </nav>

                <div class="c-header__footer">

                    <nav class="c-header__signature">

                        <hr />

                        <ul class="c-header__list">
                            <li>© Copyright Ovarian Cancer Action</li>
                            <li>Registered charity no. 1109743 (England & Wales) and SC043478 (Scotland)</li>
                        </ul>
                    </nav>
                </div>

            </div>

        </div>

    </div>

</header>
</template>

<script>
const TIMEOUT = 1;
export default {
    name: 'Header',
    mounted() {
        // From testing, without a brief timeout, it won't work.
        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT)
        }
    },
    data() {
        return {
            menuActive: false
        }
    },
    methods: {
        toggleMenu: function() {
            document.body.classList.toggle("has-navigation");
        },
        showModal() {
            this.toggleMenu();
            this.$nextTick(() => {
                this.$emit('show-modal');
            });
        },
        scrollTo: function(hashtag) {
            setTimeout(() => {
                location.href = hashtag
            }, TIMEOUT)
        }
    }
}
</script>
