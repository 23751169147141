<template>
<div class="c-main">
    <section class="c-section c-section--garden">
        <div class="c-garden">
            <div class="c-garden__inner">
                <h2 class="c-garden__title">
                    Follow our step by step video tutorials
                </h2>
                <div class="c-garden__share">
                    <h3>Click on the video below to get started!</h3>
                    <p>
                        Every rose and every donation helps to raise awareness of ovarian cancer and fund life-saving research. Help us to transform the lives and prospects of women today and for generations to come.
                    </p>
                    <div class="c-buttons">
                        <a class="c-button" @click="showModal">Upload your rose</a>
                        <a class="c-button" rel="noopener" target="_blank" href="https://donate.ovarian.org.uk/">Donate Now</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="c-section c-section--create">
        <div class="c-cards__grass">
            <svg-vue :key="componentKey" :icon="svgBanner"></svg-vue>
        </div>
        <div class="c-videos">
            <div class="c-video" v-for="video in videos">
                <span class="c-video__height"></span>
                <iframe :src="video.id" frameborder="0"> </iframe>
            </div>
        </div>
    </section>
</div>
</template>

<script>
export default {
    name: 'Create',
    mounted() {
        this.responsiveSVG();
        window.addEventListener('resize', () => {
            this.responsiveSVG();
        });
    },
    methods: {
        showModal() {
            this.$emit('show-modal');
        },
        responsiveSVG() {
            if (window.innerWidth > 1024) {
                this.svgBanner = "desktop-grass"
            } else if (window.innerWidth > 768) {
                this.svgBanner = "tablet-grass"
            } else if (window.innerWidth < 768) {
                this.svgBanner = "mobile-grass"
            }
            this.componentKey += 1;
        },
    },
    data() {
        return {
            componentKey: 0,
            svgBanner: "mobile-grass",
            videos: [{
                    id: 'https://player.vimeo.com/video/412389065?title=0&byline=0&portrait=0'
                },
                {
                    id: 'https://player.vimeo.com/video/413581438?title=0&byline=0&portrait=0'
                }
            ]
        }
    },
}
</script>
