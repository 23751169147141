<template>
<div>

    <Header @show-modal="showModal"></Header>
    <transition name="fade">
        <router-view @show-modal="showModal"></router-view>
    </transition>

    <Footer :large="largeFooter"></Footer>

    <Modal v-show="visibleModal" @close="closeModal" :max-asset-size="maxAssetSize" :key="modalKey">
    </Modal>
</div>
</template>

<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import Modal from '../components/Modal'

export default {
    name: 'app',
    components: {
        'Header': Header,
        'Footer': Footer,
        "Modal": Modal
    },
    props: {
        maxAssetSize: {
            type: Number,
            default: 0
        }
    },
    watch: {
        $route(to, from) {
            this.showLargeFooter()
            this.closeModal()
            window.scrollTo(0, 0)
            if (document.body.classList.contains("has-navigation")) {
                document.body.classList.remove("has-navigation")
            }
        }
    },
    data() {
        return {
            lastPosition: 0,
            visibleModal: false,
            largeFooter: false,
            modalKey: 0,
        }
    },
    mounted() {
        this.lazyload();
        this.detectBrowser();
        this.showLargeFooter();
        window.addEventListener('scroll', () => {
            this.scrolling()
        });
    },
    methods: {
        scrolling: function() {
            const html = document.querySelector('html');
            const currentPosition = this.getCurrentPosition();

            if (currentPosition > this.lastPosition && this.lastPosition > 80) {
                html.classList.remove('is-scrolling-up');
                html.classList.add('is-scrolling-down');
            } else {
                html.classList.remove('is-scrolling-down');
                html.classList.add('is-scrolling-up');
            }

            if (window.pageYOffset > 0) {
                html.classList.add('has-scrolled-down');
            } else {
                html.classList.remove('has-scrolled-down');
            }

            this.lastPosition = ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) ? this.lastPosition : currentPosition;
        },
        getCurrentPosition: function() {
            let currentPosition = window.pageYOffset || document.documentElement.scrollTop;
            currentPosition = (currentPosition < 0) ? 0 : currentPosition;
            return currentPosition;
        },
        lazyload: function() {
            document.addEventListener('lazybeforeunveil', function(e) {
                var bg = e.target.getAttribute('data-background');
                if (bg) {
                    e.target.style.backgroundImage = 'url(' + bg + ')';
                }
            });
        },
        showModal() {
            this.visibleModal = true;
        },
        closeModal() {
            this.visibleModal = false;
            this.modalKey += 1; // force new modal instance each time
        },
        detectBrowser() {
            let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            if (iOS) {
                const html = document.querySelector('html');
                html.classList.add('ios');
            }
        },
        showLargeFooter() {
            this.largeFooter = (this.$route.name == "Home") ? true : false;
        }
    },
}
</script>
