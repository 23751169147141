<template>
<div class="c-main">

    <section class="c-section c-section--hero  c-section--1">
        <div class="c-hero">
            <img class="c-hero__background" v-bind:src="svgBanner"></img>
            <img v-bind:src="imgBanner"></img>
        </div>
    </section>

    <ShareForm></ShareForm>

    <section class="c-section c-section--detail  c-section--3">
        <div class="c-detail c-branch" id="rose">
            <div class="c-detail__inner">
                <div class="c-detail__container">
                    <h3>Be part of the UK's largest rose garden</h3>
                    <p>
                        Once you’ve crafted your rose, upload an image of your creation to be part of the UK’s largest rose garden. Share your rose and the main symptoms of ovarian cancer on Facebook, Instagram and Twitter.
                    </p>
                    <p>
                        The earlier ovarian cancer is diagnosed, the better the outcomes. Sharing symptoms alongside your rose can save a life. Help us spread the word.
                    </p>
                    <div class="c-buttons">
                        <router-link class="c-button" :to="{name: 'Garden' }">View the garden</router-link>
                        <a class="c-button" @click="showModal">Add your rose</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="c-section c-section--bold  c-section--4">
        <div class="c-bold c-branch">
            <div class="c-bold__inner">
                <div class="c-bold__container">
                    <h2>90% of women don’t know the 4 main symptoms of ovarian cancer. Do you?</h2>
                    <p>
                        Bloating, stomach pain, weeing more, struggling to eat. Symptoms awareness saves lives. Help us spread the word.
                    </p>
                    <div class="c-buttons">
                        <a class="c-button" rel="noopener" target="_blank" href="https://ovarian.org.uk/ovarian-cancer/ovarian-cancer-symptoms/">About the symptoms</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="c-section c-section--detail c-section--5">
        <div class="c-detail c-branch" id="action">
            <div class="c-detail__inner">
                <div class="c-detail__container">
                    <h3>Ovarian Cancer Action</h3>
                    <p>
                        Ovarian Cancer Action is working towards a world where no woman dies of ovarian cancer. We're investing in research projects across three vital areas: prevention, earlier diagnosis and more effective treatments. Improvements
                        in each of these areas will transform the lives of women today and for generations to come. Help us change the future for the women we love.
                    </p>
                    <p>
                        Please know that during this turbulent time it is still important to know these symptoms and speak to your GP when there’s something wrong.
                    </p>
                    <div class="c-buttons">
                        <a class="c-button" rel="noopener" target="_blank" href="https://ovarian.org.uk/about-us/about-oca/">More about us</a>
                        <a class="c-button" rel="noopener" target="_blank" href="https://ovarian.org.uk/ovarian-cancer/i-have-symptoms-what-next/">Symptoms advice</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>
</template>

<script>
import ShareForm from '../components/ShareForm';

export default {
    name: 'Home',
    components: {
        "ShareForm": ShareForm,
    },
    mounted() {
        this.responsiveSVG();
        this.responsiveIMG();
        window.addEventListener('resize', () => {
            this.responsiveSVG();
            this.responsiveIMG();
        });
    },
    methods: {
        showModal() {
            this.$emit('show-modal');
        },
        responsiveSVG: function() {
            if (window.innerWidth > 1024) {
                this.svgBanner = "images/banner-desktop.svg"
            }
            if (window.innerWidth > 768) {
                this.svgBanner = "images/banner-desktop.svg"
            }
            if (window.innerWidth < 768) {
                this.svgBanner = "images/banner-mobile.svg"
            }
        },
        responsiveIMG: function() {
            if (window.innerWidth > 1024) {
                this.imgBanner = "images/banner-desktop.png"
            }
            if (window.innerWidth > 768) {
                this.imgBanner = "images/banner-desktop.png"
            }
            if (window.innerWidth < 768) {
                this.imgBanner = "images/banner-mobile.png"
            }
        },
    },
    data() {
        return {
            svgBanner: "images/banner-mobile.svg",
            imgBanner: "images/banner-mobile.png",
        }
    },
}
</script>
